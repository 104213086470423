import Alert from '@swe/shared/ui-kit/components/alert';
import { AlertIcon } from '@swe/shared/ui-kit/components/icon';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

type TextDisclaimerProps = ComponentHasClassName & {
  text: string;
};

const TextDisclaimer = ({ className, text }: TextDisclaimerProps) => {
  return (
    <Alert
      className={className}
      color="neutral"
      icon={AlertIcon}
      title={text}
      size="sm"
    />
  );
};

export { TextDisclaimer };
